<template>
	<SignInBlocker
		v-if="authorizationRequired"
		:web-site="webSite"
	/>
	<div
		v-else-if="!webSite || !settings"
		style="padding: 50px"
	>
		<ScLoaderSpin/>
	</div>
	<div
		v-else
		class="web-pages"
		style="width: 100%;"
		data-testid="web-pages"
	>
		<Notifications/>
		<ConfirmationsContainer/>
		<ScriptPopup
			v-if="showPublishPopup"
			:validation-result="scriptValidationResult"
			:script="publishedScript"
			:validate-in-progress="scriptValidationInProgress"
			@cancel="showPublishPopup = false"
			@click-validate="onPublishValidationCallback(false)"
			@click-force-publish="onPublishValidationCallback(true)"
		/>
		<div class="navigation" data-testid="navigation">
			<span>
				<a :href="getProjectsLink()" data-testid="projects-link"> Projects </a>
			</span>
			<span class="arrow">/</span>
			<span>
				<a :href="getProjectLink()" data-testid="project-link">
					{{ this.project && this.project.name }}
				</a>
			</span>
			<span class="arrow">/</span>
			<span> All Pages </span>
			<div style="position: absolute; right: 20px">
				<SmartwordIndicator
					v-if="smartwordInfo"
					:max-smartwords="smartwordInfo.monthlyCount"
					:current-smart-words-usage-count="smartwordInfo.currentCount"
					:subscription-name="smartwordInfo.subscriptionLevel"
					@click-top-up-balance="topUpBalance"
					@click-go-subscribtion="goToSubscription"
					@click-go-faq="goToFaq"
				/>
			</div>
		</div>
		<div v-if="webSite" class="tablebar" data-testid="tablebar">
			<div class="tablebar-col">
				<div class="header" data-testid="header">
					{{ webSite.host }}
				</div>
				<div class="tablebar-details">
					<div class="tablebar-stats" data-testid="tablebar-stats"> {{ unfilteredPages.length }} pages /
						{{ approximateWordsCount }} words
					</div>
					<div class="tablebar-crawl-widget">
						<CrawlerWidget v-if="showCrawlerWidget" :web-site="webSite" @progress="updatePages"/>
					</div>
				</div>
			</div>
			<div class="tablebar-col tablebar-col-right">
				<a
					:href="getCsvExportUrl()"
					target="_blank"
				>
					<ScButton
						view="flat"
						icon="upload"
						size="28"
						data-testid="csv-button"
					>
						Export to csv
					</ScButton>
				</a>
				<ScInput
					style="width: 350px"
					icon="search"
					placeholder="Search by page path"
					:modelValue="searchQuery"
					@input="searchQuery = $event.target.value"
					data-testid="search-input"
				/>
			</div>
		</div>
		<div
			class="body"
			style="width: 100%"
		>
			<WtPagesTable
				v-if="webSite && settings"
				:web-site="webSite"
				:settings="settings"
				:pages="unfilteredPages"
				:search-query="searchQuery"
				:customization-service="customizationService"
				:loading-pages="loadingPages"
				@edit="edit"
				@publish="publishSelected"
				@update-stats="updateStatsForSelected"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	/* eslint-disable */
	import { Component, Vue } from 'vue-property-decorator';
	import { SmartcatApiService } from '@/shared/api/smartcatApiService';
	import { ApiClient } from '@/shared/api/api-client';
	import {
		CurrentSettingsDto, PageInfo,
		PageTranslationStatisticsCalculateRequest,
		ScriptPublicationValidationResult,
		SmartcatProjectExportModel, SmartWordsInfo,
		WebPageInfo,
		WebSiteInfo,
	} from '@/shared';
	import {
		ScBadge,
		ScButton,
		ScCheckbox,
		ScIcon,
		ScInput,
		ScLoaderSpin,
		ScTooltip
	} from '@smartcat/design-system-vue2';
	import { inject } from '@/components/common/di';
	import { NotificationService } from '@/components/widgets/notifications/notificationService';
	import ScriptPopup from '@/components/widgets/script-popup/script-popup.vue';
	import { defaultLanguages } from '@/shared/resources/languages';
	import Notifications from '@/components/widgets/notifications/notifications.vue';
	import { CustomizationService } from '@/script/logic/customization/customizations';
	import SortIcon from '@/components/web-site-pages/sort-icon.vue';
	import { WebSitePageRow } from '@/components/web-site-pages/web-site-page-row';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import ConfirmationsContainer from '@/components/widgets/confirm-popup/confirmations-container.vue';
	import SignInBlocker from '@/components/web-site-pages/sign-in-blocker.vue';
	import WtPagesTable from '@/components/web-site-pages/wt-pages-table.vue';
	import CrawlerWidget from '@/components/web-site-pages/crawler-widget.vue';
	import SmartwordIndicator from '@/components/widgets/smartword-indicator/smartword-indicator.vue';

	class PageInfoCollectionItem {
		public info: PageInfo;
		public arrayIndex: number;

		constructor(info: WebPageInfo, arrayIndex: number) {
			this.info = info;
			this.arrayIndex = arrayIndex;
		}
	}

	class PageInfoCollection {
		private readonly _itemsByPagePath = new Map<string, PageInfoCollectionItem>();

		public pages: WebPageInfo[] = [];

		constructor() {
			Vue.observable(this);
		}

		public add(info: WebPageInfo) {
			const existing = this._itemsByPagePath.get(info.pagePath);

			if (existing) {
				existing.info = info;
				Vue.set(this.pages, existing.arrayIndex, info);
			} else {
				const item = new PageInfoCollectionItem(info, this.pages.length);
				this._itemsByPagePath.set(info.pagePath, item);
				this.pages.push(info);
			}
		}
	}

	@Component({
		components: {
			SmartwordIndicator,
			CrawlerWidget,
			WtPagesTable,
			SignInBlocker,
			ConfirmationsContainer,
			WtButton,
			ScTooltip,
			SortIcon,
			Notifications,
			ScriptPopup,
			ScButton,
			ScLoaderSpin,
			ScBadge,
			ScCheckbox,
			ScInput,
			ScIcon,
		},
	})
	export default class WebSitePageList extends Vue {
		public scriptValidationInProgress = false;
		public authorizationRequired = false;
		public scriptValidationFailed = false;
		public scriptValidationResult?: ScriptPublicationValidationResult = null;
		public showPublishPopup = false;
		public onPublishValidationCallback: (skipValidation: boolean) => void = null;
		public publishedScript: string = null;
		public currentPagePath: string = null;
		public searchQuery = '';
		public project: SmartcatProjectExportModel = null;
		public loadingPages = false;
		public smartwordInfo: SmartWordsInfo = null;

		private apiService = new SmartcatApiService(new ApiClient());
		private webSiteId: string = null;
		private pageInfos = new PageInfoCollection();
		private webSite: WebSiteInfo = null;
		private settings: CurrentSettingsDto = null;
		private customizationService: CustomizationService = null;
		private numFormatter = Intl.NumberFormat();
		private lastPagesUpdateTime = new Date();

		public get unfilteredPages(): WebPageInfo[] {
			return this.pageInfos.pages;
		}

		public get showCrawlerWidget(): boolean {
			if (!this.webSite || !this.customizationService) {
				return false;
			}

			const customization = this.customizationService.getCustomization()

			if (customization?.shouldHideCrawlerButton()) {
				return false;
			}

			return true;
		}

		public get approximateWordsCount() {
			let totalStatsCount = this.unfilteredPages.length * this.webSite.targetLanguages.length;
			let statsWithWordsCount = 0;
			let foundWordsCount = 0;

			for (const page of this.unfilteredPages) {
				for (const locale in page.stats.translations) {
					const stat = page.stats.translations[locale];

					if (stat?.sourceWordsCount > 0) {
						statsWithWordsCount += 1;
						foundWordsCount += stat.sourceWordsCount;
					}
				}
			}

			if (statsWithWordsCount === 0) {
				return 0;
			}

			const avgWords = Math.round(foundWordsCount / statsWithWordsCount);

			return `~ ` + this.numFormatter.format(avgWords * totalStatsCount);
		}

		public async updatePages() {
			if (this.loadingPages) {
				return;
			}

			const requestStartTime = new Date();
			const time = this.lastPagesUpdateTime;

			try {
				this.loadingPages = true;
				const response = await this.apiService.getUpdatedPages(this.webSiteId, time);

				for (const page of response.pages) {
					this.pageInfos.add(page);
				}

				this.lastPagesUpdateTime = requestStartTime;
			} finally {
				this.loadingPages = false;
			}
		}

		async mounted() {
			this.webSiteId = this.$route.params.webSiteId as string;
			this.webSite = await this.apiService.getWebSite(this.webSiteId);
			this.settings = await this.apiService.getSettings();
			this.customizationService = new CustomizationService({
				rootUrl: this.webSite.rootUrl,
				langs: [this.webSite.sourceLanguage, ...this.webSite.targetLanguages],
			});
			this.project = await this.apiService.getWebSiteProject(this.webSiteId);
			const currentUserSettings = await this.apiService.getSettings();
			if (currentUserSettings.currentAccountId !== this.webSite.accountId) {
				this.authorizationRequired = true;
				return;
			}
			this.smartwordInfo = await this.apiService.getSmartwordsInfo(this.webSiteId);
			this.loadNextPageBatch(null);
		}

		public getCsvExportUrl() {
			return '/api/web-site/' + this.webSiteId + '/pages-csv';
		}

		public async updateStatsForSelected(rows: WebSitePageRow[]) {
			for (const row of rows) {
				let page = row.page;
				let locale = row.locale;

				row.isUpdating = true;
				const req: PageTranslationStatisticsCalculateRequest = {
					webSiteId: this.webSite.id,
					pagePaths: [page.pagePath],
					locales: [locale],
				};
				let updatedPages = await this.apiService.calculateStats(req);
				page.stats = updatedPages[0].stats;
				row.isUpdating = false;
			}
		}

		public async publishSelected(rows: WebSitePageRow[], skipValidation: boolean = false) {
			this.scriptValidationInProgress = true;
			this.scriptValidationFailed = false;

			try {
				await this.apiService.publishTranslator(this.webSite.id);

				if (!skipValidation) {
					const validationResult = await this.apiService.validatePublication(this.webSite.id, null);
					if (validationResult !== ScriptPublicationValidationResult.ScriptIsOk) {
						this.scriptValidationResult = validationResult;

						if (this.showPublishPopup) {
							this.scriptValidationFailed = true;
						}

						this.publishedScript = await this.apiService.getWebSiteScript(this.webSite.id);
						this.showPublishPopup = true;
						this.onPublishValidationCallback = (skipValidation) => this.publishSelected(rows, skipValidation);
						return;
					}
				}

				for (const row of rows) {
					let page = row.page;
					let locale = row.locale;

					row.isPublishing = true;

					try {
						await this.apiService.publishByPath({
							pagePath: page.pagePath,
							webSiteId: this.webSite.id,
							locales: [locale],
						});
						this.markPublished(page, locale);
					} finally {
						row.isPublishing = false;
					}
				}
				this.showPublishPopup = false;
				inject(NotificationService).showSuccess('Page published');
			} finally {
				this.scriptValidationInProgress = false;
			}
		}

		public getProjectsLink() {
			return this.settings.smartcatApiUrl.replace(/\/$/, '') + '/projects/';
		}

		public getProjectLink() {
			return this.settings.smartcatApiUrl.replace(/\/$/, '') + '/projects/' + this.webSite.scProjectId;
		}

		public async loadNextPageBatch(nextBatchKey: string | null) {
			try {
				this.loadingPages = true;
				const response = await this.apiService.getPages(this.webSiteId, {
					batchKey: nextBatchKey,
				});

				for (const page of response.pages) {
					this.pageInfos.add(page);
				}

				if (response.nextBatchKey && response.pages.length > 0) {
					await this.loadNextPageBatch(response.nextBatchKey);
				}
			} finally {
				// for top or last call
				if (!nextBatchKey) {
					this.loadingPages = false;
					this.lastPagesUpdateTime = new Date();
				}
			}
		}

		public async topUpBalance() {
			const s = await this.apiService.getSettings();
			window.location.href = s.smartcatApiUrl.replace(/\/$/, '') + '/billing/payment-wizard?external';
		}

		public async goToSubscription() {
			const s = await this.apiService.getSettings();
			window.location.href = s.smartcatApiUrl.replace(/\/$/, '') + '/billing/payment-wizard?external';
		}

		public goToFaq() {
			window.open('https://www.smartcat.com/smartwords', '_blank');
		}

		public getFullUrl(pagePath: string, locale: string = null) {
			const defaultUrl = 'https://' + this.webSite.host + '/' + (pagePath == '__index' ? '' : pagePath);

			const customization = this.customizationService.getCustomization();
			if (customization && customization.canChangeLocale()) {
				return customization.getUrlForLocale(defaultUrl, locale) || defaultUrl;
			}
			return defaultUrl;
		}

		public async edit(row: WebSitePageRow) {
			const locale = row.locale;
			const canonicalUrl = this.getFullUrl(row.page.pagePath, locale);
			try {
				row.isEditing = true;
				const syncResults = await this.apiService.syncPageWithSmartcat({
					force: true,
					webSiteId: this.webSite.id,
					pageUrl: canonicalUrl,
					fragments: [],
					targetLang: [locale],
				});
				const pageSyncResult =
					syncResults.length === 1
						? syncResults[0]
						: syncResults.find((x) => !x.items?.[0]?.pagePath.includes('__section'));

				if (!pageSyncResult?.items?.[0]?.smartcatDocumentId) {
					console.log('No document to edit', pageSyncResult);
					return;
				}

				const docId = pageSyncResult.items[0].smartcatDocumentId.replace(/_\d*$/, '');
				const url = await this.getDocumentEditUrl(docId, locale);
				window.open(url, '_blank');
			} catch (e) {
				console.error(e);
			} finally {
				row.isEditing = false;
			}
		}

		public async publish(row: WebSitePageRow, skipValidation: boolean) {
			const pagePath = row.page.pagePath;
			const locale = row.locale;

			this.currentPagePath = pagePath;
			this.scriptValidationInProgress = true;
			this.scriptValidationFailed = false;

			try {
				await this.apiService.publishTranslator(this.webSite.id);
				if (!skipValidation) {
					const validationResult = await this.apiService.validatePublication(this.webSite.id, null);
					if (validationResult !== ScriptPublicationValidationResult.ScriptIsOk) {
						this.scriptValidationResult = validationResult;

						if (this.showPublishPopup) {
							this.scriptValidationFailed = true;
						}

						this.publishedScript = await this.apiService.getWebSiteScript(this.webSite.id);
						this.onPublishValidationCallback = (skipValidation) => {
							this.publish(row, skipValidation);
						};
						this.showPublishPopup = true;
						return;
					}
				}

				row.isPublishing = true;
				await this.apiService.publishByPath({
					pagePath,
					webSiteId: this.webSite.id,
					locales: this.webSite.targetLanguages,
				});
				this.markPublished(row.page, locale);
				this.showPublishPopup = false;
				inject(NotificationService).showSuccess('Page published');
			} finally {
				row.isPublishing = false;
				this.scriptValidationInProgress = false;
			}
		}

		public markPublished(page: WebPageInfo, locale: string) {
			if (page.publishInfo[locale]) {
				page.publishInfo[locale].hasUnpublishedChanges = false;
			} else {
				page.publishInfo[locale] = { hasUnpublishedChanges: false, locale: locale };
			}
			let saved = page.publishInfo;
			page.publishInfo = {};
			page.publishInfo = saved;
		}

		private async getDocumentEditUrl(docId: string, locale: string) {
			const s = await this.apiService.getSettings();
			const apiUrl = s.smartcatApiUrl.replace(/\/$/, '');
			const lang = defaultLanguages().find((x) => x.cultureName === locale);
			const backUrl = encodeURIComponent(window.location.href);
			const url = `${apiUrl}/editor?v=2&documentId=${docId}&languageId=${lang.id}&backUrl=${backUrl}`;
			return url;
		}
	}
</script>

<style lang="less" scoped>
@import '@smartcat/design-system-vue2/colors';

* {
	font-family: Inter, sans-serif;
}

.navigation {
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 30px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid @mulberry-purple-20;
	color: #6b6679;

	a {
		font-size: 12px;
		color: #6b6679;
		color: #6b6679;
		line-height: 20px;
	}

	.arrow {
		margin: 10px;
	}

	.head {
		padding-left: 5px;
		font-size: 14px;
		color: @mulberry-purple-50;
		line-height: 16px;
		font-weight: bold;
	}
}

.toolbar {
	display: flex;
	justify-content: space-between;
	position: absolute;
	z-index: 1000;
	left: 60px;
}

.toolbar-content {
	background: white;
	left: 44px;
	padding-top: 2px;

	.toolbar-label {
		padding: 12px;
		font-size: 12px;
		color: @mulberry-purple-90;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.sc-checkbox.sc-checkbox_checked,
.sc-checkbox__state {
	//background: @mulberry-purple !important;
	border-color: @mulberry-purple !important;
	color: #00a182 !important;
}

.tablebar {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	padding: 0 20px;
	width: 100%;

	.header {
		font-weight: bold;
		font-size: 18px;
		color: @mulberry-purple;
	}
}

.tablebar-col {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
}

.tablebar-col-right {
	align-items: flex-end;
}

.tablebar-details {
	display: flex;
	align-items: center;
}

.tablebar-crawl-widget {
	flex: 1 0 auto;
	padding-left: 10px;
	margin-left: 20px;
	border-left: 1px solid @mulberry-purple-10;
}
</style>
