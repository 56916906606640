<template>
	<div class="crawler-widget">
		<div class="crawler-widget-button">
			<ScButton
				v-if="crawlInfo && crawlInfo.crawlInProgress"
				view="flat"
				icon="pause"
				@click="cancelCrawl"
				:response="crawlRequestInProgress || waitingForCrawlStop"
				:disabled="crawlRequestInProgress || waitingForCrawlStop"
				data-testid="stop-crawling-button"
			>
				Stop crawling
			</ScButton>
			<ScTooltip v-else>
				<template #activator>
					<ScButton
						view="flat"
						icon="play"
						@click="showModal = true"
						:response="crawlRequestInProgress || waitingForCrawlStart"
						:disabled="crawlRequestInProgress || waitingForCrawlStart"
						data-testid="start-crawling-button"
					>
						Start crawling
					</ScButton>
				</template>
				<template>
					Start the process during which the words on all pages of your site will be counted
				</template>
			</ScTooltip>
		</div>
		<div
			v-if="crawlInfo && crawlInfo.statistics"
			class="crawler-widget-progress"
		>
			<div v-if="crawlInfo.crawlInProgress">
				<ScLoaderSpin
					data-testid="loader-spin"
					v-if="crawlInfo.crawlInProgress"
					view="flat"
					size="20"
				/>
				<div class="crawler-widget-stats active" data-testid="crawler-widget-stats">
					{{ crawlInfo.statistics.totalPagesCrawled }} / {{ crawlInfo.statistics.totalPagesFound }}
				</div>
			</div>
			<div v-else>
				<ScIcon
					size="14"
					name="lqa-general"
				/>
				<WtPopover position="bottom left">
					<template #activator>
						<div class="crawler-widget-stats finished" data-testid="crawler-widget-stats">
							{{ crawlInfo.statistics.totalPagesCrawled }} / {{ crawlInfo.statistics.totalPagesFound }}
						</div>
					</template>
					<template #default>
						<div class="crawler-widget-stats-detailed" data-testid="crawler-widget-stats-detailed">
							<ScText weight="semibold">Crawler</ScText>
							<div class="crawler-widget-stats-detailed-row">
								<ScText>Pages</ScText>
								<ScText data-testid="total-pages-count">{{ crawlInfo.statistics.totalPagesCrawled }}</ScText>
							</div>
							<hr />
							<div class="crawler-widget-stats-detailed-row">
								<ScText>Words</ScText>
								<ScText data-testid="total-words-count">{{ totalWordsFormatted }}</ScText>
							</div>
							<ul class="crawler-widget-stats-detailed-list">
								<li>
									<ScText>Translated</ScText>
									<ScText data-testid="translated-words-count">{{ totalWordsTranslatedFormatted }}</ScText>
								</li>
								<li>
									<ScText>Not translated</ScText>
									<ScText data-testid="not-translated-words-count">{{ totalWordsNotTranslatedFormatted }}</ScText>
								</li>
							</ul>
							<hr />
							<div class="crawler-widget-stats-detailed-row crawler-widget-date">
								<ScText>Last updated</ScText>
								<ScText data-testid="last-updated-date">{{ lastCrawlDateFormatted }}</ScText>
							</div>
						</div>
					</template>
				</WtPopover>
			</div>
		</div>
		<CrawlerPopup
			v-if="showModal"
			:root-url="webSite.rootUrl"
			:target-languages="webSite.targetLanguages"
			@confirm="startCrawl"
			@cancel="showModal = false"
		/>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { SmartcatApiService } from '@/shared/api/smartcatApiService';
	import { ApiClient } from '@/shared/api/api-client';
	import { WebSiteCrawlInfoResponse, WebSiteInfo } from '@/shared';
	import { ScButton, ScTooltip, ScBadge, ScIcon, ScLoaderSpin, ScText } from '@smartcat/design-system-vue2';
	import WtPopover from '@/components/widgets/wt-popover/wt-popover.vue';
	import CrawlerPopup, {ICrawlOptions} from '@/components/web-site-pages/crawler-popup.vue';

	@Component({
		components: {
			CrawlerPopup,
			WtPopover,
			ScTooltip,
			ScButton,
			ScBadge,
			ScIcon,
			ScLoaderSpin,
			ScText,
		},
	})
	export default class CrawlerWidget extends Vue {
		private apiService = new SmartcatApiService(new ApiClient());
		private pollInterval: number | null = null;

		@Prop({ required: true })
		public webSite: WebSiteInfo;

		public crawlRequestInProgress = false;
		public waitingForCrawlStart = false;
		public waitingForCrawlStop = false;
		public showModal = false;

		public crawlInfo: WebSiteCrawlInfoResponse = null;

		public get totalWordsFormatted() {
			if (!this.crawlInfo?.statistics) {
				return '';
			}

			const format = new Intl.NumberFormat();
			return format.format(this.crawlInfo.statistics.totalWordsFound);
		}

		public get totalWordsTranslatedFormatted() {
			if (!this.crawlInfo?.statistics) {
				return '';
			}

			const format = new Intl.NumberFormat();
			return format.format(this.crawlInfo.statistics.totalWordsTranslated);
		}

		public get totalWordsNotTranslatedFormatted() {
			if (!this.crawlInfo?.statistics) {
				return '';
			}

			const count = this.crawlInfo.statistics.totalWordsFound - this.crawlInfo.statistics.totalWordsTranslated;
			const format = new Intl.NumberFormat();
			return format.format(count);
		}

		public get lastCrawlDateFormatted() {
			if (!this.crawlInfo?.lastCrawlDate) {
				return '';
			}

			const date =
				typeof this.crawlInfo.lastCrawlDate === 'string'
					? Date.parse(this.crawlInfo.lastCrawlDate)
					: this.crawlInfo.lastCrawlDate;

			const format = new Intl.DateTimeFormat(undefined, {
				dateStyle: 'medium',
			});
			return format.format(date);
		}

		public async startCrawl(options: ICrawlOptions) {
			try {
				this.showModal = false;
				this.crawlRequestInProgress = true;
				await this.apiService.crawlWebSite({
					webSiteId: this.webSite.id,
					pathRulesToCrawl: options.pathRulesToCrawl,
					clearTranslationsMode: options.clearTranslationsMode,
					targetLanguages: options.targetLanguages
				});
				this.waitingForCrawlStart = true;
			} finally {
				this.crawlRequestInProgress = false;
			}
		}

		public async cancelCrawl() {
			if (!this.crawlInfo?.crawlInProgress) {
				return;
			}

			try {
				this.crawlRequestInProgress = true;
				await this.apiService.cancelCrawl(this.webSite.id);
				this.waitingForCrawlStop = true;
			} finally {
				this.crawlRequestInProgress = false;
			}
		}

		public async runCrawlPolling() {
			const func = async () => {
				const crawlInfo = await this.apiService.getCrawlInfo(this.webSite.id);

				if (!this.crawlInfosEqual(this.crawlInfo, crawlInfo)) {
					this.$emit('progress');
				}

				this.crawlInfo = crawlInfo;

				if (this.crawlInfo.crawlInProgress) {
					this.waitingForCrawlStart = false;
				} else {
					this.waitingForCrawlStop = false;
				}
			};

			await func();
			this.pollInterval = setInterval(func, 2000) as any;
		}

		public async mounted() {
			await this.runCrawlPolling();
		}

		public async beforeDestroy() {
			if (this.pollInterval != null) {
				clearInterval(this.pollInterval);
			}
		}

		private crawlInfosEqual(info1: WebSiteCrawlInfoResponse, info2: WebSiteCrawlInfoResponse) {
			return JSON.stringify(info1) === JSON.stringify(info2);
		}
	}
</script>

<style lang="less" scoped>
	@import '@smartcat/design-system-vue2/colors';

	* {
		font-family: Inter, sans-serif;
	}

	.crawler-widget {
		display: flex;
		align-items: center;
	}

	.crawler-widget-progress {
		display: flex;
		align-items: center;
		margin-left: 5px;

		& > div {
			display: flex;
			align-items: center;
		}
	}

	.crawler-widget-stats {
		margin-left: 5px;
		text-decoration: none;

		&.active {
			color: @mulberry-purple-35;
		}

		&.finished {
			text-decoration: underline dashed;
		}
	}

	.crawler-widget-stats-detailed {
		display: flex;
		flex-direction: column;
		gap: 6px;
		width: 260px;

		.crawler-widget-date {
			color: @mulberry-purple-35;
			opacity: 0.6;
		}

		hr {
			height: 1px;
			background: #6b6679;
			margin: 5px 0;
		}
	}

	.crawler-widget-stats-detailed-row {
		display: flex;
		justify-content: space-between;
	}

	.crawler-widget-stats-detailed-list {
		li {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 15px;
			padding-top: 2px;
			padding-bottom: 2px;

			&::before {
				position: absolute;
				top: calc(50% - 3px);
				left: 0;
				display: block;
				content: ' ';
				border-radius: 50%;
				background: white;
				height: 5px;
				width: 5px;
			}
		}
	}
</style>
