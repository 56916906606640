import { WebPageInfo } from '@/shared';

export class WebSitePageRow {
	public key: string;
	public locale: string;
	public page: WebPageInfo;
	public checked = false;
	public isEditing = false;
	public isPublishing = false;
	public isUpdating = false;

	constructor(key: string, page: WebPageInfo, locale: string) {
		this.key = key;
		this.locale = locale;
		this.page = page;
	}
}
