/* eslint-disable */
import {
	AddSectionRequest,
	CountSmartwordsRequest,
	CountSmartwordsResponse,
	CurrentSettingsDto,
	DemoWebSiteCreateRequest,
	DemoWebSiteCreateResponse,
	GetWebPagesInfoRequest,
	GetWebPagesInfoResponse,
	MixpanelSettings,
	PageScriptPublishRequest,
	PageScriptPublishRequestDto,
	PageTranslationStatisticsCalculateRequest,
	PageTranslationStatisticsUpdateRequest,
	ScriptPublicationValidationResult,
	SinglePagePublishTaskInfo,
	SmartcatProjectExportModel,
	SmartcatSyncRequestDto,
	SmartcatSyncResult,
	SmartcatSyncTaskResponse,
	SmartWordsInfo,
	WebPageInfo,
	WebPagePublishInfoResponse,
	WebPagePublishResponse,
	WebPageTranslationsCleanupRequest,
	WebPageTranslationsCleanupResponse, WebSiteCrawlInfoResponse,
	WebSiteInfo,
	WebSitePublishInfoResponse,
	WebSiteSettingsDto, WebSiteStartCrawlRequest, WebSiteStartCrawlResponse,
	WebTranslationMixpanelEvent,
	GetUpdatedPagesResponse
} from './client-types';
import { get, getString, post } from './api-utils';

export class ApiClient {
	public createDemo(request: DemoWebSiteCreateRequest) {
		return post<DemoWebSiteCreateResponse>('/api/demo', request);
	}

	public crawlWebSite(request: WebSiteStartCrawlRequest) {
		return post<WebSiteStartCrawlResponse>('/api/crawl', request);
	}

	public getCrawlInfo(webSiteId: string) {
		return get<WebSiteCrawlInfoResponse>(`/api/crawl/info?webSiteId=${webSiteId}`);
	}

	public cancelCrawl(webSiteId: string) {
		return post<any>(`/api/crawl/cancel?webSiteId=${webSiteId}`, {});
	}

	public async addSectionToPage(req: AddSectionRequest) {
		return await post<any>('/api/web-page/add-section', req);
	}

	public async createPageSyncTask(req: SmartcatSyncRequestDto) {
		return await post<SmartcatSyncTaskResponse>('/api/web-page/sync', req);
	}

	public async getPageSyncResult(taskId: string) {
		return await get<SmartcatSyncResult>('/api/web-page/sync-result/' + taskId);
	}

	public async cleanupPageTranslations(req: WebPageTranslationsCleanupRequest) {
		return await post<WebPageTranslationsCleanupResponse>('/api/web-page/cleanup', req);
	}

	public async importTranslationsFromSmartcat(req: SmartcatSyncRequestDto) {
		return await post<SmartcatSyncTaskResponse>('/api/web-page/sync-from-sc', req);
	}

	public validatePublication(webSiteId: string, pageUrl: string) {
		let url = `/api/web-site/` + webSiteId + '/validate-publication';
		if (pageUrl) {
			url += '?pageUrl=' + encodeURIComponent(pageUrl);
		}
		return get<ScriptPublicationValidationResult>(url);
	}

	public async enableSharing(webSiteId: string) {
		await post<{}>(`/api/web-site/${webSiteId}/enable-sharing`, {});
	}

	public async saveStats(req: PageTranslationStatisticsUpdateRequest) {
		await post<{}>(`/api/web-page/stats`, req);
	}

	public async getPages(webSiteId: string, req: GetWebPagesInfoRequest) {
		return await post<GetWebPagesInfoResponse>(`/api/web-site/${webSiteId}/pages`, req);
	}

	public async publish(req: PageScriptPublishRequestDto): Promise<WebPagePublishResponse> {
		return await post<WebPagePublishResponse>('/api/web-page/publish', req);
	}

	public async publishByPath(req: PageScriptPublishRequest): Promise<WebPagePublishResponse> {
		return await post<WebPagePublishResponse>('/api/web-page/publish-by-path', req);
	}

	public async getPagePublishTaskInfo(taskId: string): Promise<SinglePagePublishTaskInfo> {
		return await get<SinglePagePublishTaskInfo>(`/api/web-page/publish/info/${taskId}`);
	}

	public async getPublishedScript() {
		return await getString('/api/demo-publishing/injected-script');
	}

	public async setPublishedScript(v: string) {
		await post<void>('/api/demo-publishing/injected-script', v);
	}

	public async getWebPagePublishInfo(webSiteId: string, pagePath: string) {
		return await get<WebPagePublishInfoResponse>(
			`/api/web-page/publish-info?webSiteId=${webSiteId}&pageUrl=${encodeURIComponent(pagePath)}`,
		);
	}

	public async getSettings() {
		return await get<CurrentSettingsDto>('/api/account');
	}

	public async updateSettings(webSiteId: string, model: WebSiteSettingsDto) {
		return await post<WebSiteSettingsDto>(`/api/web-site/${webSiteId}/settings`, model);
	}

	public async getSmartwordsInfo(webSiteId: string) {
		return await get<SmartWordsInfo>(`/api/account/smart-words?webSiteId=${webSiteId}`);
	}

	public getUpdatedPages(webSiteId: string, lastUpdateTime: Date) {
		return get<GetUpdatedPagesResponse>('/api/web-site/' + webSiteId + '/updated-pages?lastUpdateTime=' + lastUpdateTime.toISOString());
	}

	public getWebSite(webSiteId: string) {
		return get<WebSiteInfo>('/api/web-site/' + webSiteId);
	}

	public syncLanguages(webSiteId: string) {
		return get<WebSiteInfo>('/api/web-site/' + webSiteId + '/sync-languages');
	}

	public addLanguage(webSiteId: string, language: string) {
		return post<WebSiteInfo>('/api/web-site/' + webSiteId + '/add-language?language=' + language, {});
	}

	public publishTranslator(webSiteId: string) {
		return post<WebSiteInfo>('/api/web-site/' + webSiteId + '/publish-translator', {});
	}

	getWebSiteProject(webSiteId: string) {
		return get<SmartcatProjectExportModel>('/api/web-site/' + webSiteId + '/project');
	}


	public getPublishInfo(webSiteId: string) {
		return get<WebSitePublishInfoResponse>('/api/web-site/' + webSiteId + '/publish-info');
	}

	public async getWebSiteScript(webSiteId: string) {
		return await getString(`/api/web-site/${webSiteId}/script`);
	}

	public countRequiredSmartwords(model: CountSmartwordsRequest) {
		return post<CountSmartwordsResponse>('/api/smartwords/count', model);
	}

	public getMixpanelSettings() {
		return get<MixpanelSettings>('/api/mixpanel/settings');
	}

	public trackMixpanelEvent(model: WebTranslationMixpanelEvent) {
		return post('/api/mixpanel/track-event', model);
	}

	async calculateStats(req: PageTranslationStatisticsCalculateRequest) {
		return post<WebPageInfo[]>('/api/web-page/calculate-stats', req);
	}

}

export const apiClient = new ApiClient();
