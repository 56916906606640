import { ICustomization } from "@/script/logic/customization/customization-interface";
import { ScriptRendererConfigV1 } from "@/shared";
import { TranslationTask } from "@/script/logic/translation/translation-task";
import { FragmentExportTask } from "@/script/logic/translation/fragment-export-task";
import { ISectionInfo } from '@/script/logic/extraction/section-info';

export class CustomizationBase implements ICustomization {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public detectLocaleByUrl(location: string): string {
		throw new Error("Method not implemented.");
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public changeLocale(newLocale: string): void {
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getUrlForLocale(url: string, newLocale: string): string {
		throw new Error('Method not implemented.');
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public isIgnored(node: Node): boolean {
		return false;
	}

	public getPageSwitchDelay(): number | null {
		return null;
	}

	public canPatchProxyUrl(): boolean {
		return false;
	}

	public patchProxyUrl(_: string, _2: string): string {
		return null;
	}

	public shouldDisableScriptForPage(): boolean {
		return false;
	}

	public selectLocaleByUrl(_: string, _2: string[]): string | null {
		return null;
	}

	public allowToCleanupPages(): boolean {
		return false;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public shouldIgnoreTask(task: TranslationTask | FragmentExportTask, pagePath: string): boolean {
		return false;
	}

	public tryGetCustomConfigForSection(_: string): ScriptRendererConfigV1 {
		return null;
	}

	public hasAdditionalLangLinks(): boolean {
		return false;
	}

	public getLangsForLinks(_: string[]): string[] {
		return [];
	}

	public canGetPath(): boolean {
		return false;
	}

	public getPath(_: string): string {
		return null;
	}

	public canChangeLocale(): boolean {
		return false;
	}

	public canDetectLocale(): boolean {
		return false;
	}

	public shouldHideCrawlerButton(): boolean {
		return false;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public findSectionForElement(element: Element): ISectionInfo | null {
		return null;
	}
}
