import { yieldAsync } from '@/shared/utils/yield';

type Comparator<T> = (a: T, b: T) => number;

export type CancellationToken = {
	isCancelled: boolean;
	cancel: () => void;
};

export function createCancellationToken(): CancellationToken {
	return {
		isCancelled: false,
		cancel() {
			this.isCancelled = true;
		},
	};
}

/**
 * asynchronous quick sort (Hoare partition scheme)
 * @param arr array to sort
 * @param compare asynchronous comparing function
 * @param timeFrameSize
 * @param ct
 * @returns {Promise.<*>}
 */
export async function quickSortAsync<T>(
	arr: T[],
	compare: Comparator<T>,
	timeFrameSize: number,
	ct: CancellationToken = null,
) {
	const left = 0;
	const right = arr.length - 1;
	let timeFrameStart = Date.now();

	const shouldStop = async () => {
		if (ct?.isCancelled) {
			return true;
		}

		const now = Date.now();
		if (now - timeFrameStart >= timeFrameSize) {
			await yieldAsync();
			timeFrameStart = now;
		}

		return false;
	};

	await quickSortAsyncInternal(arr, compare, left, right, shouldStop);
}

async function quickSortAsyncInternal<T>(
	arr: T[],
	compare: Comparator<T>,
	left: number,
	right: number,
	shouldStop: () => Promise<boolean>
) {
	if (left >= 0 && right >= 0 && left < right) {
		const p = await partition(arr, compare, left, right, shouldStop);

		if (await shouldStop())
			return;

		await quickSortAsyncInternal(arr, compare, left, p, shouldStop);

		if (await shouldStop())
			return;

		await quickSortAsyncInternal(arr, compare, p + 1, right, shouldStop);
	}
}

async function partition<T>(
	arr: T[],
	compare: Comparator<T>,
	left: number,
	right: number,
	shouldStop: () => Promise<boolean>
) {
	let i = left - 1;
	let j = right + 1;
	const pivot = arr[left];
	let tmp;

	while (true) {
		if (await shouldStop())
			return;

		do {
			i++;
		}
		while (compare(arr[i], pivot) < 0)

		if (await shouldStop())
			return;

		do {
			j--;
		}
		while (compare(arr[j], pivot) > 0)

		if (i >= j)
			return j;

		tmp = arr[j];
		arr[j] = arr[i];
		arr[i] = tmp;
	}
}
