<template>
	<div class="table-wrapper">
		<table
			class="table"
			data-testid="table"
		>
			<thead>
				<tr class="tr-top">
					<th class="table-header">
						<ScCheckbox
							:modelValue="isAllChecked()"
							@update:modelValue="checkAll($event)"
							data-testid="table-checkbox"
						>
						</ScCheckbox>
					</th>
					<th
						class="table-header"
						style="width: 100%"
					>
						<div
							class="toolbar"
							style="display: flex; width: 100%"
							v-if="isAnyChecked()"
						>
							<div
								class="toolbar-content"
								data-testid="toolbar-content"
							>
								<ScText
									size="14"
									class="toolbar-label"
									data-testid="selected-pages"
									>Selected: {{ selectedCount() }}
								</ScText>
								<ScButton
									view="flat"
									icon="upload"
									size="28"
									@click="publishSelected"
									:disabled="!isAnyChecked()"
									data-testid="publish-selected-button"
								>
									Publish selected
								</ScButton>
								<ScButton
									v-if="showRecalcButton"
									view="simple"
									size="28"
									@click="updateStatsForSelected"
									:disabled="!isAnyChecked()"
								>
									Calculate stats
								</ScButton>
							</div>
						</div>
						<a
							v-else
							@click="toggleSort('path')"
							class="sort-link"
						>
							<span>Page path</span>
							<SortIcon :sort="getSort('path')" />
						</a>
					</th>
					<th class="table-header">
						<a
							@click="toggleSort('locale')"
							class="sort-link"
						>
							Language pair
							<SortIcon :sort="getSort('locale')" />
						</a>
					</th>
					<th class="table-header">
						<a
							@click="toggleSort('ai-translated')"
							class="sort-link"
						>
							AI translation
							<SortIcon :sort="getSort('ai-translated')" />
						</a>
					</th>
					<th class="table-header">
						<a
							@click="toggleSort('human-translated')"
							class="sort-link"
						>
							Human reviewed
							<SortIcon :sort="getSort('human-translated')" />
						</a>
					</th>
					<th class="table-header">
						<a
							@click="toggleSort('words')"
							class="sort-link"
						>
							Words
							<SortIcon :sort="getSort('words')" />
						</a>
					</th>
					<th class="table-header">
						<a
							@click="toggleSort('last-published')"
							class="sort-link"
						>
							Last published
							<SortIcon :sort="getSort('last-published')" />
						</a>
					</th>
					<th class="table-header"> Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="row in rows"
					:key="row.key"
					class="page"
					data-testid="page"
				>
					<td
						class="table-value"
						data-testid="checkbox"
					>
						<div class="table-value-container">
							<ScTooltip>
								<template #activator>
									<ScCheckbox
										:modelValue="row.checked"
										@update:modelValue="setPageChecked(row, $event)"
										:disabled="!getCanPublish(row)"
									/>
								</template>
								<template v-if="!getCanPublish(row)">
									Unable to select because there is no translation to publish
								</template>
							</ScTooltip>
						</div>
					</td>
					<td
						class="table-value table-value-path"
						style="width: 100%"
						data-testid="path"
					>
						<a
							:title="row.page.pagePath"
							:href="getLink(row.page.pagePath, row.locale)"
							target="_blank"
						>
							/{{ row.page.pagePath }}
						</a>
					</td>
					<td
						class="table-value"
						data-testid="language-pair"
					>
						<div class="table-value-container table-language-pair">
							{{ webSite.sourceLanguage }}
							<ScIcon
								name="arrow-right"
								size="16"
								color="mulberry-purple-40"
							/>
							{{ row.locale }}
						</div>
					</td>
					<td
						class="table-value"
						data-testid="ai-translation"
					>
						<div class="table-value-container">
							<ScBadge
								size="small"
								:color="getAutoTranslatedCountColor(row.page, row.locale)"
								semibold
							>
								{{ getAutoTranslatedCountText(row.page, row.locale) }}
							</ScBadge>
						</div>
					</td>
					<td
						class="table-value"
						data-testid="human-reviewed"
					>
						<div class="table-value-container">
							<ScBadge
								size="small"
								:color="getScTranslatedCountColor(row.page, row.locale)"
								semibold
							>
								{{ getScTranslatedCountText(row.page, row.locale) }}
							</ScBadge>
						</div>
					</td>
					<td
						class="table-value"
						data-testid="words-count"
					>
						<div class="table-value-container">
							<WtPopover
								v-if="hasWordsCount(row) || hasError(row)"
								position="bottom right"
							>
								<template #activator>
									<ScIcon
										v-if="hasError(row)"
										name="alert"
										color="lightish-red"
										size="14"
									/>
									<ScTooltip v-if="hasWordsCount(row)">
										<template #activator>
											<span :class="getWordsCountClasses(row)">{{
												getWordsCountFormatted(row)
											}}</span>
										</template>
										<template>Approximate word count</template>
									</ScTooltip>
									<span
										v-else
										:class="getWordsCountClasses(row)"
										>Not counted</span
									>
								</template>
								<template>
									<div
										v-if="hasError(row)"
										class="table-value-count-error-details"
									>
										<ScText> The crawling was not completed due to page errors. </ScText>
									</div>
									<div
										v-else
										class="table-value-count-details"
									>
										<div>
											<ScText>Translated</ScText>
											<ScText>{{ getTranslatedWordsCountFormatted(row) }}</ScText>
										</div>
										<div>
											<ScText>Not translated</ScText>
											<ScText>{{ getNotTranslatedWordsCountFormatted(row) }}</ScText>
										</div>
									</div>
								</template>
							</WtPopover>
							<span
								v-else
								class="table-value-placeholder"
							>
								Not counted
							</span>
						</div>
					</td>
					<td
						class="table-value"
						data-testid="last-published-date"
					>
						<div class="table-value-container">
							<span v-if="isPublished(row)">
								{{ getLastPublishedDate(row.page, row.locale) }}
							</span>
							<span
								v-else
								class="table-value-placeholder"
							>
								Not published
							</span>
						</div>
					</td>
					<td class="table-value">
						<div class="table-value-container table-row-actions">
							<template v-if="row.isPublishing || row.isEditing">
								<ScLoaderSpin size="16" />
							</template>
							<template v-else>
								<ScTooltip>
									<template #activator>
										<ScButton
											icon="post-editing"
											size="28"
											view="flat"
											:disabled="!getCanEdit(row)"
											@click="editRow(row)"
											data-testid="edit-button"
										/>
									</template>
									<ScText v-if="getCanEdit(row)">Edit in Smartcat</ScText>
									<ScText v-else>No content to edit</ScText>
								</ScTooltip>
								<ScTooltip>
									<template #activator>
										<ScButton
											icon="upload"
											size="28"
											view="flat"
											@click="publishRow(row)"
											:disabled="!getCanPublish(row)"
											data-testid="publish-button"
										/>
									</template>
									<ScText v-if="getCanPublish(row)">Publish</ScText>
									<ScText v-else>No translation to publish</ScText>
								</ScTooltip>
							</template>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="table-loader">
			<ScLoaderSpin
				v-if="loadingPages"
				size="32"
			/>
		</div>
		<div
			v-if="!loadingPages && rows.length === 500"
			class="page-limit-message"
		>
			<ScText>
				List of pages is limited by {{ rows.length }} items. Use search or filters to see other pages.
			</ScText>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import {
		ScBadge,
		ScButton,
		ScCheckbox,
		ScIcon,
		ScLoaderSpin,
		ScText,
		ScTooltip,
	} from '@smartcat/design-system-vue2';
	import SortIcon from '@/components/web-site-pages/sort-icon.vue';

	import { CurrentSettingsDto, WebPageInfo, WebSiteInfo } from '@/shared';
	import { WebSitePageRow } from '@/components/web-site-pages/web-site-page-row';
	import { debounce } from '@/shared/utils/debounce';
	import { CustomizationService } from '@/script/logic/customization/customizations';
	import WtPopover from '@/components/widgets/wt-popover/wt-popover.vue';
	import {CancellationToken, createCancellationToken, quickSortAsync} from '@/shared/utils/sort';

	export type SortColumn =
		| 'default'
		| 'locale'
		| 'last-published'
		| 'ai-translated'
		| 'human-translated'
		| 'path'
		| 'is-unpublished'
		| 'words';

	@Component({
		components: { ScText, WtPopover, ScTooltip, ScBadge, ScButton, SortIcon, ScCheckbox, ScIcon, ScLoaderSpin },
	})
	export default class WTPagesTable extends Vue {
		@Prop({ required: true })
		public webSite: WebSiteInfo;

		@Prop({ required: true })
		public settings: CurrentSettingsDto;

		@Prop({ required: true })
		public customizationService: CustomizationService;

		@Prop({ default: [] })
		public pages: WebPageInfo[];

		@Prop({ default: '' })
		public searchQuery: string;

		@Prop({ default: false })
		public loadingPages: boolean;

		public rows: WebSitePageRow[] = [];
		public applySortAndFilterDebounced = debounce(() => this.applySortAndFilter(), 500);
		public showRecalcButton = false;

		private sortCol: SortColumn = 'default';
		private sortDirection = 'asc';
		private sortCancellationToken: CancellationToken = null;
		private numFormatter = Intl.NumberFormat();

		@Watch('pages')
		public onPagesChanged() {
			this.applySortAndFilterDebounced();
		}

		@Watch('searchQuery')
		public onSearchQueryChanged() {
			this.applySortAndFilterDebounced();
		}

		public async mounted() {
			this.showRecalcButton = window.location.href.includes('recalc');
		}

		public hasWordsCount(row: WebSitePageRow): boolean {
			return !!row.page?.stats?.translations?.[row.locale];
		}

		public hasError(row: WebSitePageRow) {
			return row.page.failedToCrawl;
		}

		public getWordsCountClasses(row: WebSitePageRow) {
			return {
				'table-value-count': true,
				'table-value-count-error': this.hasError(row),
			};
		}

		public getTranslatedWordsCountFormatted(row: WebSitePageRow) {
			const stats = row.page.stats.translations[row.locale];
			return this.numFormatter.format(stats.translatedWordsCount);
		}

		public getNotTranslatedWordsCountFormatted(row: WebSitePageRow) {
			const stats = row.page.stats.translations[row.locale];
			const count = Math.max(0, stats.sourceWordsCount - stats.translatedWordsCount);
			return this.numFormatter.format(count);
		}

		public getWordsCount(row: WebSitePageRow) {
			return row.page.stats.translations[row.locale].sourceWordsCount;
		}

		public getWordsCountFormatted(row: WebSitePageRow) {
			return this.numFormatter.format(this.getWordsCount(row));
		}

		public getCanEdit(row: WebSitePageRow) {
			return this.hasWordsCount(row) && this.getWordsCount(row) > 0;
		}

		public getCanPublish(row: WebSitePageRow) {
			const publishInfo = row.page.publishInfo?.[row.locale];
			const stats = row.page.stats?.translations?.[row.locale];

			return publishInfo?.publishedAt || publishInfo?.hasUnpublishedChanges || stats?.translatedWordsCount > 0;
		}

		public editRow(row: WebSitePageRow) {
			this.$emit('edit', row);
		}

		public publishRow(row: WebSitePageRow) {
			this.$emit('publish', [row]);
		}

		public updateStatsForSelected() {
			this.$emit('publish', this.getSelectedRows());
		}

		public publishSelected() {
			this.$emit('publish', this.getSelectedRows());
		}

		public getSelectedRows() {
			return this.rows.filter((x) => x.checked);
		}

		public getSort(col: SortColumn) {
			if (this.sortCol === col) {
				return this.sortDirection;
			}
			return '';
		}

		public toggleSort(col: SortColumn) {
			if (this.sortCol === col) {
				this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
			} else {
				this.sortCol = col;
				this.sortDirection = 'asc';
			}
			this.applySortAndFilter();
		}

		public async applySortAndFilter() {
			this.sortCancellationToken?.cancel();
			const token = this.sortCancellationToken = createCancellationToken();

			const currentRowsByKeys = new Map<string, WebSitePageRow>(this.rows.map((x) => [x.key, x]));

			const res: WebSitePageRow[] = [];

			for (const page of this.pages) {
				if (this.searchQuery && !page.pagePath.includes(this.searchQuery)) {
					continue;
				}

				for (const locale of this.webSite.targetLanguages) {
					const key = page.pagePath + locale;
					const existing = currentRowsByKeys.get(key);

					if (existing) {
						existing.page = page;
					}

					res.push(existing || new WebSitePageRow(key, page, locale));
				}
			}

			console.log('Filtered rows count', res.length);
			const startTime = Date.now();

			const sortResult = this.sortDirection === 'asc' ? -1 : 1;
			await quickSortAsync(res, (a: WebSitePageRow, b: WebSitePageRow) => {
				const val1 = this.getSortCriterionValue(a);
				const val2 = this.getSortCriterionValue(b);

				if (val2 == val1) {
					return 0;
				}

				return val2 > val1 ? sortResult : -sortResult;
			}, 50, this.sortCancellationToken);

			if (token.isCancelled) {
				return;
			}

			console.log('Sort took ', Date.now() - startTime);

			this.rows = res.slice(0, 500);
		}

		public isPublished(row: WebSitePageRow) {
			return !!row.page.publishInfo?.[row.locale]?.publishedAt;
		}

		public isAnyChecked(): boolean {
			return !!this.rows.find((x) => x.checked);
		}

		public selectedCount(): number {
			return this.rows.reduce((c, x) => c + +x.checked, 0);
		}

		public isAllChecked(): boolean {
			return this.rows.length > 0 && this.rows.every((x) => x.checked || !this.getCanPublish(x));
		}

		public setPageChecked(row: WebSitePageRow, v: boolean) {
			row.checked = v;
		}

		public checkAll(v: boolean) {
			this.rows.forEach((x) => {
				if (this.getCanPublish(x)) {
					this.setPageChecked(x, v);
				}
			});
		}

		public getLink(pagePath: string, locale: string) {
			if (!this.webSite || pagePath.includes('__sections/')) {
				return null;
			}
			const fullUrl = this.getFullUrl(pagePath, locale);
			return `/browser/${this.webSite.id}?pageUrl=${encodeURIComponent(fullUrl)}&scProxyLang=${locale}`;
		}

		public getFullUrl(pagePath: string, locale: string = null) {
			const defaultUrl = 'https://' + this.webSite.host + '/' + (pagePath == '__index' ? '' : pagePath);

			const customization = this.customizationService.getCustomization();
			if (customization && customization.canChangeLocale()) {
				return customization.getUrlForLocale(defaultUrl, locale) || defaultUrl;
			}
			return defaultUrl;
		}

		public getLastPublishedDate(page: WebPageInfo, locale: string) {
			const publishInfo = page.publishInfo?.[locale];
			if (!publishInfo || !publishInfo.publishedAt) {
				return 'Not published';
			}
			return this.formatDate(publishInfo.publishedAt);
		}

		public getAutoTranslatedCount(page: WebPageInfo, locale: string) {
			const stats = page?.stats?.translations[locale];
			if (stats) {
				return Math.floor(
					(100 * (stats.autoTranslatedFragmentCount + stats.scTranslatedFragmentCount)) /
						stats.sourceFragmentCount,
				);
			}
			return null;
		}

		public getScTranslatedCountColor(page: WebPageInfo, locale: string) {
			const count = this.getScTranslatedCount(page, locale);
			if (count == null || isNaN(count)) {
				return 'grey';
			}
			if (count === 0) {
				return 'grey';
			}
			if (count == 100) {
				return 'green';
			}
			return 'purple';
		}

		public getAutoTranslatedCountColor(page: WebPageInfo, locale: string) {
			const count = this.getAutoTranslatedCount(page, locale);
			if (count == null || isNaN(count)) {
				return 'grey';
			}
			if (count === 0) {
				return 'grey';
			}
			if (count == 100) {
				return 'green';
			}
			return 'purple';
		}

		public getAutoTranslatedCountText(page: WebPageInfo, locale: string) {
			const count = this.getAutoTranslatedCount(page, locale);
			if (!isFinite(count)) {
				return '?';
			}
			if (count == null || isNaN(count)) {
				return '0%';
			}
			return count + '%';
		}

		public getScTranslatedCountText(page: WebPageInfo, locale: string) {
			const count = this.getScTranslatedCount(page, locale);
			if (!isFinite(count)) {
				return '?';
			}
			if (count == null || isNaN(count)) {
				return '0%';
			}
			return count + '%';
		}

		public getScTranslatedCount(page: WebPageInfo, locale: string) {
			const stats = page?.stats?.translations[locale];
			if (stats) {
				return Math.floor((100 * stats.scTranslatedFragmentCount) / stats.sourceFragmentCount);
			}
			return null;
		}

		private formatDate(d: Date | string) {
			const v = typeof d === 'string' ? new Date(d) : d;
			const twoDigits = function (n: number) {
				return (n < 10 ? '0' : '') + n;
			};
			return (
				v.getFullYear() +
				'-' +
				twoDigits(v.getMonth() + 1) +
				'-' +
				twoDigits(v.getDate()) +
				' ' +
				twoDigits(v.getHours()) +
				':' +
				twoDigits(v.getMinutes())
			);
		}

		private getSortCriterionValue(a: WebSitePageRow) {
			const locale = a.locale;
			const translation = a.page.stats?.translations[locale];

			switch (this.sortCol) {
				case 'default':
					return 0;
				case 'locale':
					return locale;
				case 'last-published':
					return a.page.publishInfo[a.locale]?.publishedAt;
				case 'ai-translated':
					return translation?.sourceFragmentCount
						? translation.autoTranslatedFragmentCount / translation.sourceFragmentCount
						: 0;
				case 'human-translated':
					return translation?.sourceFragmentCount
						? translation.scTranslatedFragmentCount / translation.sourceFragmentCount
						: 0;
				case 'path':
					return a.page.pagePath;
				case 'is-unpublished':
					return a.page.publishInfo[a.locale]?.hasUnpublishedChanges;
				case 'words':
					return translation?.sourceWordsCount ?? 0;
			}
		}
	}
</script>

<style scoped lang="less">
	@import '@smartcat/design-system-vue2/colors';

	* {
		font-family: Inter, sans-serif;
	}

	.table-loader {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		margin-left: 29px;
	}

	.table-wrapper {
		padding: 0 20px;
	}

	.toolbar-content {
		display: flex;
		align-items: center;
		gap: 8px;
		text-transform: none;
	}

	.table {
		border-spacing: 0;
		margin-top: 10px;

		.tr-top {
			position: sticky;
			top: 0;
			background: white;
			z-index: 11;
		}

		tr td {
			border-top: 1px solid #f2f1f4;
		}

		tr td,
		tr th {
			&:last-child {
				border-left: 1px solid #f2f1f4;
			}
		}

		a {
			color: @mulberry-purple-80;
			text-decoration: underline;
		}

		.table-header {
			color: @mulberry-purple-40;
			user-select: none;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 12px;
			text-align: left;
			padding: 14px 12px;
			line-height: 20px;
			font-weight: 600;
			text-transform: uppercase;
			height: 53px;
			vertical-align: middle;

			.sort-link {
				color: @mulberry-purple-40;
				text-decoration: none;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				cursor: pointer;
			}
		}

		.table-value {
			max-width: 300px;
			color: @mulberry-purple-80;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 13px;
			text-align: left;
			font-weight: 400;
			padding: 14px 12px;
			line-height: 20px;

			.table-value-container {
				display: flex;
				align-items: center;
			}
		}

		.table-value-placeholder {
			color: @mulberry-purple-40;
		}
	}

	.table-language-pair {
		gap: 5px;
	}

	.table-value-path {
		overflow: hidden;
	}

	.table-value-count {
		text-decoration: underline;
		text-decoration-style: dashed;
		text-underline-offset: 3px;

		&.table-value-count-error {
			margin-left: 5px;
		}
	}

	.table-value-count-error-details {
		max-width: 250px;
		white-space: initial;
	}

	.table-value-count-details {
		display: flex;
		flex-direction: column;
		gap: 5px;

		& > div {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.page-limit-message {
		display: flex;
		justify-content: center;
		margin: 20px 0;
	}
</style>
