import { render, staticRenderFns } from "./web-site-page-list.vue?vue&type=template&id=5fa2d882&scoped=true&"
import script from "./web-site-page-list.vue?vue&type=script&lang=ts&"
export * from "./web-site-page-list.vue?vue&type=script&lang=ts&"
import style0 from "./web-site-page-list.vue?vue&type=style&index=0&id=5fa2d882&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa2d882",
  null
  
)

export default component.exports