import { trimEndChar, trimStartChar } from './string';

export class ProxiedUrl {
	constructor(
		public protocol: string,
		public pathAndQuery: string,
		public slug: string,
		public lang: string,
		public region: string,
		public proxyHost: string,
		public port: string = null,
	) {}
}

export function proxyUrlToString(url: ProxiedUrl) {
	const host = `${url.slug}-${url.lang}-${url.region}.${url.proxyHost}`;
	const path = trimStartChar(url.pathAndQuery, '/');

	return `${url.protocol}://${host}${url.port ? ':' + url.port : ''}/${path}`;
}

export function proxyUrlToOriginalUrl(url: ProxiedUrl, originalHost: string) {
	const path = trimStartChar(url.pathAndQuery, '/');
	return `https://${originalHost}/${path}`;
}

export function urlToOriginalUrl(url: string, originalHost: string) {
	return proxyUrlToOriginalUrl(parseProxyUrl(url), originalHost);
}

export function originalUrlToProxyUrl(url: string, locale: string, format: string) {
	const parsed = new URL(url);
	const proxyUrl = parseProxyUrl(format.replace('{locale}', 'en'));
	proxyUrl.pathAndQuery = parsed.pathname + parsed.search;
	proxyUrl.lang = locale;
	return proxyUrlToString(proxyUrl);
}

export function parseProxyUrl(url: string): ProxiedUrl | null {
	const uri = new URL(url);
	const regex = /^(?<slug>.*?)-(?<lang>[^.]*)-(?<region>[a-z]+?)\.(?<proxyHost>.*)$/;
	const match = uri.hostname.match(regex);
	if (!match || match.length === 0) {
		return null;
	}

	return new ProxiedUrl(
		trimEndChar(uri.protocol, ':'),
		uri.pathname + uri.search,
		match.groups.slug,
		match.groups.lang,
		match.groups.region,
		match.groups.proxyHost.split(':')[0],
		uri.port || null,
	);
}
