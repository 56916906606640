import { SysmexCustomization } from '@/script/logic/customization/sysmex';
import { ICustomization } from '@/script/logic/customization/customization-interface';
import { CustomizationCfg } from '@/script/logic/customization/customization-cfg';
import { InokimCustomization } from "@/script/logic/customization/inokim";
import { NfteCustomization } from '@/script/logic/customization/nfte';
import { NfpCustomization } from "@/script/logic/customization/nfpCustomization";
import { HclCustomization } from "@/script/logic/customization/hclCustomization";
import { GitbookCustomization } from '@/script/logic/customization/gitbook-customization';

export class CustomizationService {
	private readonly _cfg: CustomizationCfg;
	private _customization: ICustomization | null;
	private _scannedForCustomization = false;

	constructor(cfg: CustomizationCfg) {
		this._cfg = cfg;
	}

	public getCustomization(): ICustomization {
		if (this._customization || this._scannedForCustomization) {
			return this._customization;
		}

		const rootUrl = this._cfg.rootUrl;

		if (!rootUrl) {
			return null;
		}

		if (rootUrl.includes('nfp')) {
			this._customization = new NfpCustomization();
		}
		else if (rootUrl.includes('inokim')) {
			this._customization = new InokimCustomization(this._cfg);
		}
		else if (rootUrl.includes('sysmex')) {
			this._customization = new SysmexCustomization(this._cfg);
		}
		else if (rootUrl.includes('innovation.nfte')) {
			this._customization = new NfteCustomization(this._cfg);
		}
		else if (rootUrl.includes('help.hcltechsw.com')) {
			this._customization = new HclCustomization();
		}
		else if (document?.head?.querySelector('link[href="https://static.gitbook.com"]') || rootUrl.includes('gitbook')) {
			this._customization = new GitbookCustomization();
			console.log('Created gitbook customization');
		}

		this._scannedForCustomization = true;

		return this._customization;
	}
}
